.top_select[data-v-2ce250d6] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon[data-v-2ce250d6] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-2ce250d6] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon .iconfont icon-cuowu[data-v-2ce250d6] {
  color: #f56c6c;
}
.item_icon .iconfont icon-duihao[data-v-2ce250d6] {
  color: #67c23a;
}
.item_icon .el-icon-circle-check[data-v-2ce250d6] {
  color: #67c23a;
}
.item_icon .iconfont icon-info[data-v-2ce250d6] {
  color: #409eff;
}
.item_icon .iconfont icon-cuowu[data-v-2ce250d6] {
  color: #e6a23c;
}
.item_icon .iconfont icon-cuowu-outline[data-v-2ce250d6] {
  color: #e6a23c;
}
.item_icon p[data-v-2ce250d6] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.sbcg[data-v-2ce250d6] {
  padding: 0 !important;
}
.sbyc[data-v-2ce250d6] {
  color: red !important;
  padding: 0 !important;
}